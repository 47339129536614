<template>
  <div class="legal">
  	<Nav />
  	<Breadcrumbs :items="crumbs"/>
    <div class="legal__main">
    	<h1 class="ml-0 mt-3">MEDEA MEDICAL PRODUCTS PRIVACY POLICY</h1>
			<hr>
			<div>
				<p>
					Protecting your private information is Medea Medical Products’ (“Medea”) priority.  This Privacy Policy applies to www.medeappe.com (the “Website”) and Medea, and governs data collection and usage. Any reference to Medea below shall be deemed to refer to Medea and the Website.  By using the Website, you consent to the data practices described in this Privacy Policy.
				</p>
      	<h3>Collection of Your Personal Information </h3>
      	<p>In order to better provide you with products and services offered on the Website, Medea may collect personally identifiable information.  We do not collect any personal information about you unless you voluntarily provide it to us.  However, you may be required to provide certain personal information to us when you elect to use certain services available on the Website.  These may include: (a) registering for an account on the Website; and/or (b) sending us an electronic message.  We may use your information for, but not limited to, communicating with you related to services and/or products you have requested from us.  We may also gather additional personal or non-personal information in the future.</p>
      	<h3>Use of Your Personal Information</h3>
      	<p>Medea may collect and use your personal information to operate the Website and deliver services you have requested.  Medea may also use your personally identifiable information to inform you of other products or services available from Medea.</p>
      	<h3>Sharing Information With Third Parties</h3>
      	<p>Medea does not sell, rent or lease its customer lists to third parties.  Medea may share data with trusted partners to help perform statistical analysis, send you electronic messages or postal mail, provide customer support, or arrange for deliveries.</p>
      	<p>Medea may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) comply with the law or comply with legal process served on Medea; (b) protect and defend the rights or property of Medea; and/or (c) act under exigent circumstances to protect the personal safety of Website users or the public.</p>
      	<h3>Tracking User Behavior</h3>
      	<p>Medea may keep track of the websites and pages our users visit within the Website in order to determine what Medea services are the most popular.  This data is used to deliver customized content and advertising within Medea to customers whose behavior indicates that they are interested in a particular subject area.</p>
      	<h3>Automatically Collected Information</h3>
      	<p>Information about your computer hardware and software may be automatically collected by Medea. This information can include: your IP address, browser type, domain names, access times and referring website addresses.  This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the Website.</p>
      	<h3>Cookies</h3>
      	<p>The Website may use cookies to help you personalize your online experience.  A cookie is a text file that is placed on your hard disk by a web page server.  Cookies cannot be used to run programs or deliver viruses to your computer.  Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issues the cookie to you.</p>
      	<p>One of the primary purposes of cookies is to provide a convenience feature to save you time.  The purpose of a cookie is to tell the Web server that you have returned to a specific page.  For example, if you personalize Website pages, or register with the Website or Website services, a cookie helps the Website recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping address, etc.  When you return to the Website, the information you previously provided can be retrieved, so you can easily use the Website features you customized.</p>
      	<p>You have the ability to accept or decline cookies.  Most Web browsers automatically accept cookies, but you can typically modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Website services.</p>
      	<h3>Links</h3>
      	<p>The Website may contain links to other websites.  Please be aware that Medea is not responsible for the content or privacy practices of such other websites.  We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.</p>
      	<h3>Security of Personal Information</h3>
      	<p>Medea secures your personal information from unauthorized access, use, or disclosure using SSL Protocol.  We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information.  However, no data transmission over the internet or any wireless network can be guaranteed to be entirely secure.  As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through the Website cannot be guaranteed.</p>
      	<h3>Right to Delete</h3>
      	<p>Subject certain exceptions set out below, on receipt of a verifiable request from you, we will: (1) delete your personal information from our records; and (2) direct any service providers to delete your personal information from their records.</p>
      	<p>Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:  (1) complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with applicable law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us; (2) detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity; (3) debug to identify and repair errors that impair existing intended functionality; (4) comply with the California Electronics Communications Privacy Act; (5) enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us; (6) comply with an existing legal obligation; or (7) otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.</p>
      	<h3>Children Under Thirteen</h3>
      	<p>Medea does not knowingly collect personally identifiable information from children under the age of thirteen.  If you are under the age of thirteen, you are not authorized to use the Website.</p>
      	<h3>
      		Email Communications
      	</h3>
      	<p>From time to time, Medea may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communications.</p>
      	<h3>External Data Storage Sites</h3>
      	<p>We may store your data on servers provided by third party hosting vendors with whom we have contracted.</p>
      	<h3>Changes to this Statement</h3>
      	<p>Medea reserves the right to change this Privacy Policy from time to time.  We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account by placing a prominent notice on the Website, and/or by updating any privacy information on this page. Your continued use of the Website and/or services through the Website after such modifications will constitute your acknowledgement of the modified Privacy Policy and agreement to abide and be bound by that modified Privacy Policy.</p>
      	<h3>Contract Information</h3>
      	<p>If you have questions or comments regarding this Privacy Policy or the Website, or you believe that Medea has not adhered to this Policy, you may contact Medea at:</p>
      	<address>
      		Medea Medical Products<br>
					5653 Stoneridge Dr., Ste. 119<br>
					Pleasanton, CA 94588<br>
					info@medeamp.com<br>
					(925) 425-9282
      	</address>	
      	<p>Effective as of December 21, 2020</p>
      </div>
    </div>
  <Footer />
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  name: 'privacy',
  computed: {
    ...mapState(['currentUser', 'userProfile', 'customerProfile']),
    crumbs () {
      let crumbsArray = []
      let step1 = { title: "Home", to: { name: "home"}}
	    let step2 = { title: "Privacy Policy", to: false}
      crumbsArray.push(step1)
      crumbsArray.push(step2)
      return crumbsArray
    },
  },
  components: {
  	Nav,
  	Footer,
  	Breadcrumbs
  }
}
</script>